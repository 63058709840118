import styled from "styled-components"
import { motion } from "framer-motion"
import { breakpoints } from "../../../utils/styles"

export const Section = styled.div`
  background: ${({ theme }) => theme.gradient.tanker};
  overflow: hidden;
  padding: 96px 32px;

  @media (max-width: ${breakpoints.l}px) {
    padding: 64px 24px;
  }
`

export const Content = styled(motion.div)`
  color: ${({ theme }) => theme.color.white};
  max-width: 900px;
  min-height: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: ${breakpoints.l}px) {
    min-height: 400px;
  }

  @media (max-width: ${breakpoints.s}px) {
    text-align: left;
    align-items: flex-start;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  text-align: center;
  margin: 0 0 48px;
`

export const Image = styled(motion.img)`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto 4px;

  @media (max-width: ${breakpoints.xl}px) {
    margin: 0;
  }
`

export const Waves = styled.img`
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;

  @media (max-width: ${breakpoints.s}px) {
    bottom: 3px; 
  }
`

export const Description = styled.p`
  font-size: 24px;
  line-height: 32px;

  @media (max-width: ${breakpoints.l}px) {
    font-size: 16px;
    line-height: 24px;
  }
`
