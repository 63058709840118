import React, { useLayoutEffect } from "react"
import PropTypes from "prop-types"
import ReactHtmlParser from "react-html-parser"
import { useInView } from "react-intersection-observer"
import { useAnimation } from "framer-motion"

import Heading, { Type } from "../../UI/Heading"

import { Section, Content, Image, Description, ImageContainer, Waves } from "./styles"

import tankerImage from "../../../assets/conflicts/tanker/tanker.png"
import wavesImage from "../../../assets/conflicts/tanker/waves.png"

const contentVariants = {
  visible: { scale: 1, opacity: 1 },
  hidden: { scale: 1.2, opacity: 0 },
}

const tankerAnimation = {
  x: ["-0.4vw", "0.4vw", "-0.4vw"],
  y: ["0vw", "-0.2vw", "0vw"],
  rotate: [-1, 1, -1],

}
const tankerTransition = {
  duration: 4,
  ease: "easeInOut",
  repeat: Infinity,
}

const TankerParallax = ({ title, description }) => {
  const [contentRef, inContentView] = useInView()
  const contentControls = useAnimation()

  useLayoutEffect(() => {
    if (inContentView) {
      contentControls.start("visible")
    }
  }, [contentControls, inContentView])

  return (
    <Section>
      <ImageContainer>
        <Waves src={wavesImage} alt="waves" />
        <Image
          transition={tankerTransition}
          animate={tankerAnimation}
          src={tankerImage} 
          alt="tanker" 
        />
      </ImageContainer>
      <Content
        ref={contentRef}
        animate={contentControls}
        transition={{ duration: 0.8 }}
        variants={contentVariants}
        initial="hidden"
      >
        <Heading type={Type.h2}>{title}</Heading>
        <Description>{ReactHtmlParser(description)}</Description>
      </Content>
    </Section>
  )
}

TankerParallax.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default TankerParallax
